import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {UnstyledButton, Group, LoadingOverlay} from '@mantine/core';
import {Month} from '@mantine/dates';
import dayjs from "dayjs";
import 'dayjs/locale/nl';
require('prerender-node').set('prerenderToken', 'wMADexqDXM0hV4dBCKik');

const AvailabilityCalenderSection = ({stayID, bvHouseCode}) => {
    dayjs.locale('nl');
    const [date, setDate] = useState(new Date());
    const [bookedDates, setBookedDates] = useState();
    const [loading, setLoading] = useState(true);

    /** Our availability date **/
    useEffect(() => {
        if (!bvHouseCode) {
            fetch(`/wp-json/v1/accommodations/stays/${stayID}/availability?skip_cache=1`)
                .then(data => data.json())
                .then(bookedDates => setBookedDates(bookedDates))
                .then(() => setLoading(false))
        }
    }, [bvHouseCode])

    /** BelVilla availability date **/
    useEffect(() => {
        if (bvHouseCode) {
            fetch(`/wp-json/v1/belvilla/availability?housecode=${bvHouseCode}`)
                .then(data => data.json())
                .then(data => setBookedDates(data.booked_dates))
                .then(() => setLoading(false))
        }
    }, [bvHouseCode])

    function ChangeMonthButton({nextMonth}) {
        const previousMonthAllowed = dayjs(date).toDate() > dayjs().toDate();

        return (
            <UnstyledButton
                className={nextMonth ? 'float-right' : 'float-left'}
                onClick={() => {
                    if (previousMonthAllowed || nextMonth) {
                        setDate(dayjs(date).add((nextMonth ? 1 : -1), 'month').toDate())
                    }
                }}
            >
                <Group>
                    <div>
                        {!nextMonth && (
                            <i className="fas fa-chevron-circle-left" style={{color: '#F39203'}}/>
                        )}

                        <span className="text-capitalize font-weight-bold mx-2" style={{color: '#F39203'}}>
                            {dayjs(date).add((nextMonth ? 2 : -1), 'month').format('MMMM')}
                        </span>

                        {nextMonth && (
                            <i className="fas fa-chevron-circle-right" style={{color: '#F39203'}}/>
                        )}
                    </div>
                </Group>
            </UnstyledButton>
        )
    }

    const isBooked = (date, bookedDates) => bookedDates.find(d => d === dayjs(date).format('YYYY-MM-DD'))

    return (
        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={loading} loaderProps={{color: 'orange'}}/>
            <h3 className="text-center">{dayjs(date).format('YYYY')}</h3>
            <div className="row">
                <div className="col-12 col-md-6">
                    <ChangeMonthButton nextMonth={false}/>

                    <h4 className="mb-4 text-center text-capitalize">
                        {dayjs(date).format('MMMM')}
                    </h4>

                    <Month
                        month={date}
                        minDate={(dayjs().startOf('month').toDate())}
                        disableOutsideEvents={true}
                        dayClassName={(date, type) => {
                            if (type.weekend && !type.disabled) return 'weekend';
                            if (type.outside) return 'outside-date';
                            if (type.disabled) return 'booked-date';
                        }}
                        excludeDate={(date) => bookedDates && isBooked(date, bookedDates)}
                        fullWidth
                        locale="nl"
                    />
                </div>
                <div className="d-none d-md-block col-md-6">
                    <ChangeMonthButton nextMonth={true}/>

                    <h4 className="mb-4 text-center text-capitalize">
                        {dayjs(date).add(1, 'month').format('MMMM')}
                    </h4>

                    <Month
                        month={dayjs(date).add(1, 'month').toDate()}
                        disableOutsideEvents={true}
                        dayClassName={(date, type) => {
                            if (type.weekend && !type.disabled) return 'weekend';
                            if (type.outside) return 'outside-date';
                            if (type.disabled) return 'booked-date';
                        }}
                        excludeDate={(date) => bookedDates && isBooked(date, bookedDates)}
                        fullWidth
                        locale="nl"
                    />
                </div>
            </div>
        </div>
    )
}

document.addEventListener('DOMContentLoaded', () => {
    const entry = document.querySelector('#stay-availability-calender-section');
    const stayID = entry.attributes.itemid.value;
    const bvHouseCode = entry.dataset.bv_house_code;
    ReactDOM.render(
        <Router>
            <Switch>
                <AvailabilityCalenderSection stayID={stayID} bvHouseCode={bvHouseCode}/>
            </Switch>
        </Router>, entry);
});
